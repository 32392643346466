// export const myAction = async ({ commit }) => {

// }

import manufacturingApi from "@/api/manufacturingApi"
import Cookies from "js-cookie";


const configureLogin = () => {
    // Set default authorization headers before each request
    manufacturingApi.interceptors.request.use(
        (config) => {
            let token = Cookies.get('session-token')
            if (token)
                config.headers.common['Authorization'] = `Token ${token}`;
            return config;
        },
        (error) => Promise.reject(error),
    );        
}

const removeLogin = () => {
    Cookies.remove('session-token')
}

export const login = async ({ commit }, loginData) => {
    console.log(loginData)
    try {
        const { data } = await manufacturingApi.post('/auth/login/', loginData )
        console.log(data)
        let token = data['key']
        Cookies.set("session-token", token)
        configureLogin(token)

        const resp = await manufacturingApi.get('/auth/user/')
        console.log(resp);
        commit('loginSuccess', resp.data)

    } catch(error) {
        removeLogin()
        console.log(error);
        console.log("login fail")
        commit('loginFailure')
    }
}


export const logout = async ({ commit }) => {
    console.log("Login out user");
    const { data } = await manufacturingApi.post('/auth/logout/')
    console.log(data);
    removeLogin()
    commit('logout')
}

export const loadLoginData = async ({ commit }) => {
    console.log("Loading user data");
    try {
        let token = Cookies.get("session-token")
        configureLogin(token)

        const { data } = await manufacturingApi.get('/auth/user/')
        commit('loginSuccess', data)
    } catch  (error) {
        removeLogin()
        commit('loginFailure')
    }
}
