import store from '@/store'

export const isAuthenticatedGuard = async( to, from, next ) => {
    await store.dispatch('users/loadLoginData')
    return new Promise(() => {
        console.log("Check if any user is logged in");
        store.getters['users/isUserLoggedIn']
        if (store.getters['users/isUserLoggedIn']) {
            console.log(store.getters['users/getUser']);
            next()
        } else {
            console.log("No user logged in");
            next({name: 'login' })
        }
    })
}

export const isManagerAuthenticatedGuard = async( to, from, next ) => {    
    await store.dispatch('users/loadLoginData')
    return new Promise(() => {
        if (store.getters['users/isUserLoggedIn']) {
            if (store.getters['users/isUserManager']) {
                next()
            } else {
                next({name: 'login' })
            }
        } else {
            console.log("No user logged in");
            next({name: 'login' })
        }
    })

}