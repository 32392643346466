export default {
    name: 'profile',

    component: () => import(
        /* webpackChunkName: "layout" */ 
        '@/modules/profile/layouts/MainLayout'
    ),

    children: [
        {
            path: '/profile',
            name: 'profile-home',
            component: () => import(
                /* webpackChunkName: "ProfileView" */ 
                '@/modules/profile/views/Profile'
            ),
        }
    ]

}