export default {
    name: 'backoffice',

    component: () => import(
        /* webpackChunkName: "layout" */ 
        '@/modules/backoffice/layouts/MainLayout'
    ),

    children: [
        {
            path: '/products',
            name: 'backoffice-products',
            component: () => import(
                /* webpackChunkName: "ProductsView" */ 
                '@/modules/backoffice/views/Products'
            ),
        },
        {
            path: '/orders',
            name: 'backoffice-orders',
            component: () => import(
                /* webpackChunkName: "OrdersSummaryView" */ 
                '@/modules/backoffice/views/Orders'
            ),
        },
        {
            path: '/registries',
            name: 'backoffice-registries',
            component: () => import(
                /* webpackChunkName: "RegistriesView" */ 
                '@/modules/backoffice/views/Registries'
            ),
        },
        {
            path: '/backoffice/users',
            name: 'backoffice-users',
            component: () => import(
                /* webpackChunkName: "UsersView" */ 
                '@/modules/backoffice/views/Users'
            ),
        },
        {
            path: '/backoffice/dashboard/notifications',
            name: 'backoffice-notifications',
            component: () => import(
                /* webpackChunkName: "NotificationsView" */ 
                '@/modules/backoffice/views/Notifications'
            ),
        },
        {
            path: '/backoffice/dashboard/products',
            name: 'backoffice-dashboard-products',
            component: () => import(
                /* webpackChunkName: "DashboardProductsView" */ 
                '@/modules/backoffice/views/DashboardProducts'
            ),
        },
        { 
            path: '/backoffice/dashboard/products/:id', 
            name: 'backoffice-dashboard-products-details',
            component: () => import(
                /*webpackChunkName: "DashboardProductDetails" */ 
                '@/modules/backoffice/views/DashboardProductDetails'
            ),
            props: (route) => {
                const id = Number(route.params.id)
                return {
                    'idProduct': isNaN(id) ? 1 : id
                }
            }
        },
        {
            path: '/backoffice/dashboard/orders',
            name: 'backoffice-dashboard-orders',
            component: () => import(
                /* webpackChunkName: "DashboardOrdersView" */ 
                '@/modules/backoffice/views/DashboardOrders'
            ),
        },
        {
            path: '/backoffice/dashboard/orders/all',
            name: 'backoffice-dashboard-orders-all',
            component: () => import(
                /* webpackChunkName: "DashboardOrdersAllView" */ 
                '@/modules/backoffice/views/DashboardOrdersAll'
            ),
        },
        {
            path: '/backoffice/dashboard/orders/finished',
            name: 'backoffice-dashboard-orders-finished',
            component: () => import(
                /* webpackChunkName: "DashboardOrdersFinishedView" */ 
                '@/modules/backoffice/views/DashboardOrdersFinished'
            ),
        },
        { 
            path: '/backoffice/dashboard/orders/:id', 
            name: 'backoffice-dashboard-order-details',
            component: () => import(
                /*webpackChunkName: "DashboardOrderDetails" */ 
                '@/modules/backoffice/views/DashboardOrderDetails'
            ),
            props: (route) => {
                const id = Number(route.params.id)
                return {
                    'idOrder': isNaN(id) ? 1 : id
                }
            }
        },
        {
            path: '/backoffice/dashboard/registries',
            name: 'backoffice-dashboard-registries',
            component: () => import(
                /* webpackChunkName: "DashboardRegistriesView" */ 
                '@/modules/backoffice/views/DashboardRegistries'
            ),
        },
        {
            path: '/backoffice/dashboard/registries/closed/:start_date/:end_date',
            name: 'backoffice-dashboard-registries-closed',
            component: () => import(
                /* webpackChunkName: "DashboardRegistriesClosedView" */ 
                '@/modules/backoffice/views/DashboardRegistriesClosed'
            ),
            props: (route) => {
                return {
                    'start_date': new Date(route.params.start_date),
                    'end_date': new Date(route.params.end_date)
                }
            }
        },
    ]

}