// export const myAction = async ({ commit }) => {

// }

import manufacturingApi from "@/api/manufacturingApi"
import Cookies from "js-cookie";

export const loadWorkOrders = async ({ commit }) => {
    console.log("Loading work orders");
    const { data } = await manufacturingApi.get('/orders/active/', { withCredentials: true } )
    console.log(data);
    if (!data) {
        commit('setOrders', [])
        return
    }
    const workOrders = []
    for(let id of Object.keys( data )) {
        workOrders.push({
           id,
           ...data[id]
       })
    }
    
    commit('setOrders', workOrders)
}


export const loadUserLastEvent = async ({ commit }, workOrderId=undefined) => {
    console.log("Loading last event");
    let url = (workOrderId) ? `/events/${workOrderId}` : '/events/';
    const { data } = await manufacturingApi.get(url, { withCredentials: true } )
    console.debug(data);
    if (!data) {
        commit('setLastEvent', null)    
        return
    }
    const urlNotifications = `registries/${data['register_id']}/notify-error/`;
    const { data: notifications } = await manufacturingApi.get(urlNotifications, {
        withCredentials: true
    })
    commit('setNotifiedErrors', notifications)
    commit('setLastEvent', data)
}

export const sendCheckEvent = async ({ commit }, workOrderId) => {
    let url = `/events/`
    const { data } = await manufacturingApi.post(url, { "production_order": workOrderId }, { 
        withCredentials: true,
        headers: {'X-CSRFToken': Cookies.get('csrftoken')}
    })
    console.log(data);

    commit('setLastEvent', data)
    commit('resetNotifiedErrors')

}

export const notifyError = async ({ commit }, payload) => {
    const url = `registries/${payload['registryId']}/notify-error/`;
    const { data } = await manufacturingApi.post(url, payload['data'], {
        withCredentials: true,
        headers: {'X-CSRFToken': Cookies.get('csrftoken')}
    })
    console.debug(data);
    commit('increaseNotifiedErrors')
}

export const setLoading = ({ commit }, value) => {
    commit('setLoading', value)
}