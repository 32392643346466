// export const myGetter = ( state ) => {
// return state
// }

// const ROLE_OPERATOR_CODE = 1
const ROLE_MANAGER_CODE = 2


export const isUserLoggedIn = ( state ) => {
    console.log({...state.user})
    return state.user != null
}

export const isUserManager = ( state ) => {
    return state.user.role === ROLE_MANAGER_CODE
}

export const getUser = ( state ) => {
    return {...state.user}
}
