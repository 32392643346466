import { createStore } from 'vuex'
import orders from '@//modules/checks/store/orders';
import users from '@//modules/users/store/login';

const store = createStore({
  modules: {
    orders,
    users
  },
  
  //
  // Global store
  //

  state: {
    packageVersion: process.env.PACKAGE_VERSION || '0'
  },
  
  getters: {
    appVersion: (state) => {
      return state.packageVersion
    }
  }
})

export default store