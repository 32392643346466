// export const myGetter = ( state ) => {
// return state
// }

export const getWorkOrdersSelectData= ( state ) => {
    let elements = []
    state.workOrders.forEach(element => {
        elements.push({ label: element.code, code: element.id })
    })
    return elements
}

export const getWorkOrderById = ( state ) => (id) => {
    const order =  state.workOrders.find((element) => element.id === id)

    if (!order){
        return
    }

    return { ...order }
}


export const getLastEvent = ( state ) => {
    return {...state.lastEvent}
}

export const getNumErrorsNotified = ( state ) => {
    if ( state.numErrorsNotified > 99) {
        return '99+'
    }
    return state.numErrorsNotified
}
