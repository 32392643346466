// export const myMutation = ( state ) => {

// }

export const setOrders = ( state, workOrders ) => {
    state.workOrders = [...workOrders]
    state.isLoading = false
}

export const setLastEvent = ( state, event ) => {
    console.log(event);
    state.lastEvent = event
}

export const increaseNotifiedErrors = ( state ) => {
    state.numErrorsNotified++
}

export const setNotifiedErrors = ( state, notifications ) => {
    state.numErrorsNotified = notifications.length
}

export const resetNotifiedErrors = ( state ) => {
    state.numErrorsNotified = 0
}

export const setLoading = ( state, value ) => {
    state.isLoading = value
}