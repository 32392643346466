import { isAuthenticatedGuard } from '@/router/auth-guard'

export default {
    name: 'users',

    component: () => import(
        /* webpackChunkName: "layout" */ 
        '@/modules/users/layouts/MainLayout'
    ),

    children: [
        {
            path: '/login',
            name: 'login',
            component: () => import(
                /* webpackChunkName: "LoginView" */ 
                '@/modules/users/views/Login'
            ),
        },
        {
            path: '/logout',
            name: 'logout',
            beforeEnter: [
                isAuthenticatedGuard
            ],
            component: () => import(
                /* webpackChunkName: "LogoutView" */ 
                '@/modules/users/views/Logout'
            ),
        }
    ]

}