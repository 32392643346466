export default {
    name: 'checks',

    component: () => import(
        /* webpackChunkName: "layout" */ 
        '@/modules/checks/layouts/MainLayout'
    ),

    children: [
        {
            path: '',
            name: 'checkin',
            component: () => import(
                /* webpackChunkName: "checkin" */ 
                '@/modules/checks/views/CheckView'
            ),
        }
    ]

}