import { createRouter, createWebHashHistory } from 'vue-router'
import checksRouter from '../modules/checks/router';
import usersRouter from '../modules/users/router';
import backofficeRouter from '../modules/backoffice/router';
import profileRouter from '../modules/profile/router';
import { isAuthenticatedGuard, isManagerAuthenticatedGuard } from './auth-guard'

const routes = [
  {
    path: '/users', 
    ...usersRouter
  },
  {
    path: '', 
    beforeEnter: [
      isAuthenticatedGuard
    ],
    ...checksRouter
  },
  {
    path: '/profile', 
    beforeEnter: [
      isAuthenticatedGuard
    ],
    ...profileRouter
  },
  {
    path: '/backoffice', 
    beforeEnter: [
      isManagerAuthenticatedGuard
    ],
    ...backofficeRouter
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes
})

export default router
